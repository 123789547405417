import React, { useState, useEffect } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  Input,
  Field,
  Button,
  Control,
  LoadingAnimation,
  StaticButton,
  Columns,
  Column,
} from "../../../StyleComponents/styles"
import { LeafletCard, ColumnDiv2 } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "IP Address Lookup",
    link: "/find-location-of-ip-address/",
  },
]
const seeAlsoArray = [
  "/ip-address",
  "/where-am-i-location-latitude-longitude",
  "/latitude-and-longitude-to-dns-converter",
]

function IPAddressLocation(props) {
  let [loading, setLoading] = useState(false)
  let [resultError, setresultError] = useState("")
  let [ipSearch, setipSearch] = useState("")
  let [ipAddress, setipAddress] = useState("")
  let [data, setData] = useState({})
  let [leaflet, setLeaflet] = useState(null)

  let url = "https://api.ip.sb/geoip"

  function ipSearchC(e) {
    setipSearch(e.target.value)
  }

  useEffect(() => {
    fetching(url)
  }, [])

  function finLocationFunction(e) {
    e.preventDefault()
    let value = ipSearch
    let newUrL = url + value
    if (value === "") {
      fetching(url)
    } else {
      fetching(newUrL)
    }
  }

  function fetching(theURL) {
    if (loading === false) {
      setLoading(true)
      fetch(theURL)
        .then(function (resp) {
          return resp.json()
        })
        .then(function (data) {
          resultError !== "" && setresultError("")
          setipAddress(data.ip)
          setData(data)
          import("../../../components/MapLeaflet/MapLeaflet")
            .then(module => {
              const MapLeaflet = module.default
              setLeaflet(
                <MapLeaflet
                  latitude={data.latitude}
                  longitude={data.longitude}
                  ip={data.ip}
                />
              )
            })
            .catch(error => console.log(error))
        })
        .catch(function (error) {
          setresultError("Error, Please try again.")
          console.log(error)
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Find Location Of Any IP Address"
        description="Find Location Of Any IP Address, Simple Online tool to find IP Address Location, what is my IP Address?"
        keywords={[
          "find Location ip, find ip address, search Location ip,Location search ip address,my ip,my ip address, ip address,ip lookup, ip location, ip address lookup,ip info,ip geolocation, my ip and location,ip,my address,get ip,ip information, info from ip,get Currency from ip,Timezone from ip.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Location Search For IP Address</Title>
        <form onSubmit={finLocationFunction}>
          <Field>
            <Control>
              <StaticButton>IP Address</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                type="text"
                borderColor={resultError !== "" ? "red" : ""}
                value={ipSearch}
                onChange={ipSearchC}
                disabled={loading ? true : ""}
                // onKeyDown={makeEnter}
              />
            </Control>
            <Control>
              <Button
                margin="0"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                borderLeftColor={resultError !== "" ? "red" : ""}
                className="staticButton"
                onClick={finLocationFunction}
                disabled={loading ? true : ""}
              >
                Find Location
              </Button>
            </Control>
          </Field>
        </form>
        <br />
        <p>{resultError}</p>
        <Columns>
          <Column>
            {leaflet || loading ? (
              <LeafletCard>{leaflet}</LeafletCard>
            ) : (
              <LoadingAnimation />
            )}
          </Column>
          <Column>
            {data.latitude || loading ? (
              <>
                <p>
                  IP Address : <strong>{ipAddress}</strong>
                </p>
                <ColumnDiv2>
                  {Object.keys(data).map((e, index) => {
                    return <p key={index}>{`${e}: ${data[e]}`}</p>
                  })}
                  <br />
                </ColumnDiv2>
              </>
            ) : (
              <LoadingAnimation />
            )}
          </Column>
        </Columns>
        <br />
        <h3>IP Address</h3>
        <p>
          An IP address lookup will discover the geolocation of any IP. The
          results will show you the city, state/region, postal/zip code.
          Internet Protocol (IP address) exists a numerical identifier attached
          to each device connected to an internet network.
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default IPAddressLocation
